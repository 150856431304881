<template>
  <div class="filing-completed-component">
    <h2>
      {{ heading }} {{ company.name }}!
    </h2>
    <p>
      {{ description }}
    </p>
    <picture>
      <source
        :srcset="require('@images/illustrations/stageline/balloon-animation.webp')"
        type="image/webp"
      >
      <img
        :src="require('@images/illustrations/stageline/balloon-animation.gif')"
        alt="Balloon Animation"
        class="balloon-animation"
      >
    </picture>
    <div class="continue-button-container">
      <primary-button
        :button-text="navButtonText"
        @onclick="$emit('next-slide')"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FilingCompleted',
  components: {
    PrimaryButton: () => import('@/components/StagelineV2/shared/PrimaryButton'),
  },
  props: {
    // Add new allowedValues to filingType object as needed
    filingType: {
      type: String,
      required: true,
      validator: (value) => {
        const allowedValues = [
          'form a company',
        ]
        return allowedValues.includes(value)
      },
    },
  },
  data() {
    return {
      heading: "Congratulations,",
      navButtonText: "Continue",
      formationSubText: " and formed your company",
    }
  },
  computed: {
    ...mapGetters('stageline', [
        'company',
      ]
    ),
    formationProduct() {
      return this.filingType === 'form a company'
    },
    subText() {
      return this.formationProduct ? this.formationSubText : ''
    },
    description() {
      return `You’ve completed your filing${this.subText}—an important milestone and accomplishment towards business success.`
    },
  },
}
</script>
<style lang="scss" scoped>
.filing-completed-component {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  h2 {
    width: 100%;
    margin-bottom: 0.5em;
    font-size: 2em;
    font-weight: $ct-ui-font-weight-6;
  }

  picture {

    .balloon-animation {
      max-width: 31.25em;
      padding: 0.8em 0;
    }
  }

  .continue-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
