var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filing-completed-component" }, [
    _c("h2", [
      _vm._v(
        "\n    " +
          _vm._s(_vm.heading) +
          " " +
          _vm._s(_vm.company.name) +
          "!\n  "
      ),
    ]),
    _c("p", [_vm._v("\n    " + _vm._s(_vm.description) + "\n  ")]),
    _c("picture", [
      _c("source", {
        attrs: {
          srcset: require("@images/illustrations/stageline/balloon-animation.webp"),
          type: "image/webp",
        },
      }),
      _c("img", {
        staticClass: "balloon-animation",
        attrs: {
          src: require("@images/illustrations/stageline/balloon-animation.gif"),
          alt: "Balloon Animation",
        },
      }),
    ]),
    _c(
      "div",
      { staticClass: "continue-button-container" },
      [
        _c("primary-button", {
          attrs: { "button-text": _vm.navButtonText },
          on: {
            onclick: function ($event) {
              return _vm.$emit("next-slide")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }